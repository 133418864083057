require('jquery-migrate')
require('jquery-ujs')

// TODO: using require.context to import all
//       and store all exported behaviors in a namespace
// Meta programming to export everything from a folder
const ResourceExports = {};
function importAll (r) {
  r.keys().forEach(key => {
    let imported = r(key);
    for (const attrName in imported) {
      if (imported.hasOwnProperty(attrName)) {
        ResourceExports[attrName] = imported[attrName];
      }
    }
  });
}

importAll(require.context("../resources", true, /\.*(\.js|\.js.erb)$/));

module.exports = ResourceExports