// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

// Select the text input if clicked anywhere in the header except the link
$(document).on('click', '.locus-grid-header-container', function(e){
  if ( $(e.target).is('a')) {
    return;
  }
  $(this).find('#area_search_display').prop('disabled', false).select();

})

$(document).on('click', '#areas_index .new-area-button', function () {
  $(this).addClass('hidden');
  $('.area-new').removeClass('hidden');
  $('.area-new').addClass('anim-slide-in-200');
  $('.area-new input[type=search]').focus();

  $('.map-controls').css('transform', 'translateY(124px)');
});

$(document).on('click', '#areas_index .location-search-result', function () {
  var data = $(this).data();
  var area = $(this).data('area');
  var area_type = $(this).data('area-type');
  var country = $(this).data('country');
  var container_number = $(this).data('container-number');
  var form = $('.areas-index-form');
  var scale_by_type = form.find('#containers_' + container_number + '_scale_by_type').val();
  var scale_by_value = form.find('#containers_' + container_number + '_scale_by_value').val();
  var data_source = $(this).data('data-source');
  form.find('#containers_' + container_number + '_scale_by_type').val(scale_by_type);
  form.find('#containers_' + container_number + '_scale_by_value').val(scale_by_value);

  form.find('#containers_' + container_number + '_area').val(area);
  form.find('#containers_' + container_number + '_area_type').val(area_type);
  form.find('#containers_' + container_number + '_country').val(country);
  form.find('#containers_' + container_number + '_activity_count').val(4);
  form.find('#containers_' + container_number + '_data_source').val(data_source);
  form.find('#containers_' + container_number + '_hierarchy_id').val('');
  $(this).closest(".location-search-results").empty();
  form.submit();
});

$(document).on('keypress', '#area_search_search_term', function (e) {
  if (e.which == 13) {
    $(this).parent('form').submit();
    return false;
  }
})

$(document).on('click', '.locus-grid-panel .x-button-icon', function(){
  var container = $(this).closest('.area-container'),
       number = container.data('container-number') === 1 ? 0 : 1;
  remove_compare(number);
});

$(document).on('click', '.cancel-compare-link', function(){
  $('.area-new').removeClass('anim-slide-in-200');

  application.Animations.animateOutAndHide(element='.area-new', animation='anim-slide-out-200');
  application.Animations.unhideAndAnimateIn(element=$('.new-area-button'), animation='anim-slide-in-200');

  $('.map-controls').css('transform', 'translateY(0)');
})

$(document).on('click', '.company-list-collapse', function(e) {
  e.preventDefault();
  var analyst_id = $(this).data('analyst-id')
  var target = '.target[data-analyst-id="' + analyst_id + '"]';
  $(target).html('');
  $('.company-list-expand[data-analyst-id="' + analyst_id + '"]').removeClass('hidden')
  $('.company-list-collapse[data-analyst-id="' + analyst_id + '"]').addClass('hidden')
})

// handler for treemap_cell when clicked, on area show page
$(document).on('treemap_cell_click', '#areas_show', function (event) {
  var base_url = window.location.href.split('?')[0],
    url_params = new URLSearchParams(window.location.search);

  if (event.detail.is_selected_sector) {
    url_params.delete('sector');
  } else {
    url_params.set('sector', event.detail.data);
  }

  Turbolinks.visit(base_url + '?' + url_params.toString());
});

function remove_compare(number) {
  //grab old vals
  var form = $('.areas-index-form'),

  area = form.find('#containers_' + number + '_area').val(),
  area_type = form.find('#containers_' + number + '_area_type').val(),
  country = form.find('#containers_' + number + '_country').val(),
  activity_count = form.find('#containers_' + number + '_activity_count').val(),
  scale_by_type = form.find('#containers_' + number + '_scale_by_type').val(),
  scale_by_value = form.find('#containers_' + number + '_scale_by_value').val(),
  agg_method = form.find('#containers_' + number + '_agg_method').val(),
  barcode_type = form.find('#containers_' + number + '_barcode_type').val(),
  location_quotient_activity = form.find('#containers_' + number + '_location_quotient_activity').val(),
  location_quotient_product = form.find('#containers_' + number + '_location_quotient_product').val();
  marker = form.find('#containers_' + number + '_marker').val();
  data_source = form.find('#containers_' + number + '_data_source').val();
  hierarchy_id = form.find('#containers_' + number + '_hierarchy_id').val();

  //reset second part of form
  form.find('#containers_1_area').val('');
  form.find('#containers_1_area_type').val('');
  form.find('#containers_1_country').val('');
  form.find('#containers_1_activity_count').val('');
  form.find('#containers_1_scale_by_type').val('');
  form.find('#containers_1_scale_by_value').val('');
  form.find('#containers_1_agg_method').val('');
  form.find('#containers_1_barcode_type').val('');
  form.find('#containers_1_location_quotient_activity').val('');
  form.find('#containers_1_location_quotient_product').val('');
  form.find('#containers_1_data_source').val('');
  form.find('#containers_1_hierarchy_id').val('');
  form.find('#containers_1_marker').val('');

  form.find('#containers_0_area').val(area);
  form.find('#containers_0_area_type').val(area_type);
  form.find('#containers_0_country').val(country);
  form.find('#containers_0_activity_count').val(activity_count);
  form.find('#containers_0_scale_by_type').val(scale_by_type);
  form.find('#containers_0_scale_by_value').val(scale_by_value);
  form.find('#containers_0_agg_method').val(agg_method);
  form.find('#containers_0_barcode_type').val(barcode_type);
  form.find('#containers_0_location_quotient_activity').val(location_quotient_activity);
  form.find('#containers_0_location_quotient_product').val(location_quotient_product);
  form.find('#containers_0_data_source').val(data_source);
  form.find('#containers_0_hierarchy_id').val(hierarchy_id);
  form.find('#containers_0_marker').val(marker);

  form.submit();
}

$(document).on('click', '.locus-grid-expand-collapse-button', function() {
  var parent_grid = $(this).closest('.locus-grid-panel');
  // TODO - better toggle animation through CSS
  parent_grid.toggleClass('locus-grid-collapsed');
  parent_grid.find('.locus-grid-header-n-visual-container').slideToggle(200);
  parent_grid.find('.locus-grid-expand-collapse-button img').toggleClass('is-collapsed');
})

// regional statistics plus minus button event handler
$(document).on('click', '.regional-statistics-toggle-btn', function(e) {
  $('#census-dropdown-' + $(this).data('container-number')).slideToggle(200);
  $(this).find('.plus-minus-icon').toggleClass('show-minus-icon');
  e.stopPropagation();
});

$(document).on('click', '.tab-header', function(e) {
  if ($(this).hasClass('active')) return true;

  var counter_header  = $(this).siblings('.tab-header.active'),
      container_number = $(this).data('container-number')
      target_tab_key  = $(this).data('tab-key'),
      counter_tab_key = counter_header.data('tab-key'),
      target_content  = $('#tab_content_' + target_tab_key + "_" + container_number),
      counter_content = $('#tab_content_' + counter_tab_key + "_" + container_number);

  counter_content.removeClass('active');
  target_content.addClass('active');

  counter_header.removeClass('active');
  $(this).addClass('active');
});

$(document).on('grid_box_click', '#areas_show', function (event) {
  var base_url = window.location.href.split('?')[0],
      url_params = new URLSearchParams(window.location.search);

  url_params_activity = url_params.get('activity');
  url_params_resource = url_params.get('resource');

  if (url_params_activity === event.detail.activity && url_params_resource === event.detail.resource) {
    url_params.delete('activity');
    url_params.delete('resource');
  } else {
    url_params.set('activity', event.detail.activity);
    url_params.set('resource', event.detail.resource);
  }

  url_params.delete('sector');
  url_params.delete('page');

  Turbolinks.visit(base_url + '?' + url_params.toString());
});

$(document).on('grid_label_click', '#areas_show', function (event) {
  var base_url = window.location.href.split('?')[0],
      url_params = new URLSearchParams(window.location.search);

  url_params_activity = url_params.get('activity');
  url_params_resource = url_params.get('resource');

  url_params.delete('activity');
  url_params.delete('resource');
  url_params.delete('page');
  url_params.delete('sector');

  if (url_params_activity !== null && url_params_resource !== null) {
    url_params.set(event.detail.type, event.detail.label);
  } else {
    if ((event.detail.type === "activity" && url_params_activity !== event.detail.label) || (event.detail.type === "resource" && url_params_resource !== event.detail.label)) {
      url_params.set(event.detail.type, event.detail.label);
    }
  }

  Turbolinks.visit(base_url + '?' + url_params.toString());
});

// set the subdivision field in the area_index_form depending on radio_button selection and then submit it
$(document).on('change', '.region-view input', function (){
  var value = $(this).val(),
      container_number = $(this).parents(".area-container").data("container-number"),
      area_index_form = $(".areas-index-form");

  area_index_form.find("#containers_"+container_number+"_subdivision").val(value);
  area_index_form.submit();
});

$(document).on('click', '.num-jobs-business input', function(){
  var form = $('.areas-index-form'),
      container_number = $(this).parents('.area-container').data('container-number');

  form.find('#containers_' + container_number + '_location_quotient_activity').val("");
  form.find('#containers_' + container_number + '_location_quotient_product').val("");
  form.submit();
});

$(document).on('click', '.region-view-controls, .map-color-controls, .map-marker-controls', function (){
  var maps_control_header = $(this).parents(".maps-control-header"),
      collapsible_container = maps_control_header.find(".collapsible-container");

  // need to call toggle_region_view function before slideToggle because region_view_collapse_div.is(":visible") is always true when in transition
  toggle_collapsible_container(maps_control_header, collapsible_container.is(":visible"));
  collapsible_container.slideToggle(200);
});

// @param collapsible_container[jquery selector]
// @param expect_to_show [Boolean]
function toggle_collapsible_container(collapsible_container, expect_to_show){
  // Toggle icon
  collapsible_container.find(".plus-minus-icon").toggleClass('show-minus-icon', !expect_to_show);
}

$(document).on('click', '.locus-toggle-business', function(){
  toggle_grid($(this), 'enterprise');
});

$(document).on('click', '.locus-toggle-labor', function(){
  toggle_grid($(this), 'job');
});

function toggle_grid(current_context, selected_barcode_type) {
  var area_index_form    = $(".areas-index-form"),
    container_number   = current_context.closest('.area-container').data("container-number"),
    current_barcode_type = area_index_form.find("#containers_" + container_number + "_barcode_type"),
    current_agg_method = area_index_form.find("#containers_" + container_number + "_agg_method"),
    current_barcode_type_value = current_barcode_type.val();
    current_agg_method_value = selected_barcode_type == 'enterprise' ? 'company_count' : 'job_count'

  if (current_barcode_type_value !== selected_barcode_type) {
    current_barcode_type.val(selected_barcode_type);
    current_agg_method.val(current_agg_method_value);
    area_index_form.submit();
  }
}

$(document).on('click', '.grid-config-btn', function() {
  $(this).find('.plus-minus-icon').toggleClass('show-minus-icon');
  $(this).siblings('.grid-config-options').slideToggle(200);
});

$(document).on('click', '#scale_box_by_options .styled-radio-button-card', function (e) {
  var container_number = $(this).closest('.area-container').data('container-number'),
  scale_by_value = $(this).find('#max_box_size').val(),
  scale_by_option = $(this).find("input[name="+container_number+"_box_max]").val(),
  form = $('.areas-index-form');

  form.find('#containers_'+container_number+'_scale_by_type').val(scale_by_option);
  //TODO: move validation to controller
  if (scale_by_value > 0 && scale_by_value <= 100 || scale_by_option === 'relative') {
    form.submit();
  }
})

$(document).on('click', '#aggregated_by_options .styled-radio-button-card', function (e) {
  var container_number = $(this).closest('.area-container').data('container-number'),
  agg_method = $(this).find("input[name="+$(this).find('label').attr("for")+"]").val(),
  form = $('.areas-index-form');

  form.find('#containers_'+container_number+'_agg_method').val(agg_method);

  form.submit();
})

$(document).on('click', '#max_box_size', function(e){
  e.stopPropagation();
})


$(document).on('change', "#max_box_size", function () {
    var scale_by_value = $(this).val(),
       container_number = $(this).closest('.area-container').data('container-number'),
       form = $('.areas-index-form');

  form.find('#containers_' + container_number + '_scale_by_type').val('percentage');
  form.find('#containers_' + container_number + '_scale_by_value').val(scale_by_value);


  $(this).parent().siblings('input[type=radio]').attr('checked', true)

  //TODO: move validation to controller
  if (scale_by_value > 0 && scale_by_value <= 100){
    form.submit();
  }
})

$(document).on('change', '.activity-section input', function (){
  var container_number = $(this).parents(".area-container").data("container-number"),
      area_index_form = $(".areas-index-form"),
      location_quotient_product = area_index_form.find("#containers_"+container_number+"_location_quotient_product"),
      activity_val = $(this).val();

  // when only activity is selected using the radio button, location_quotient_product should be set to "ANY"
  if(location_quotient_product.val() == ''){ location_quotient_product.val('ANY'); }

  area_index_form.find("#containers_"+container_number+"_location_quotient_activity").val(activity_val);
  area_index_form.submit();
});

$(document).on('change', '.product-section input', function (){
  var container_number = $(this).parents(".area-container").data("container-number"),
      area_index_form = $(".areas-index-form"),
      location_quotient_activity = area_index_form.find("#containers_"+container_number+"_location_quotient_activity"),
      product_val = $(this).val();

  // when only product is selected using the radio button, location_quotient_activity should be set to "ANY"
  if(location_quotient_activity.val() == ''){ location_quotient_activity.val('ANY'); }

  area_index_form.find("#containers_"+container_number+"_location_quotient_product").val(product_val);
  area_index_form.submit();
});

// this is to set the location_quotient values in the area_index_form on selecting the grid box
$(document).on('grid_box_click', '#areas_index', function (event) {
  var area_index_form = $(".areas-index-form"),
      activity_val = event.detail.activity,
      product_val = event.detail.resource,
      container_num = event.detail.container_num,
      loc_quotient_activity_selector = area_index_form.find("#containers_"+container_num+"_location_quotient_activity"),
      loc_quotient_product_selector = area_index_form.find("#containers_"+container_num+"_location_quotient_product");

  // if user clicks the same grid box again, then location_quotient should be reset, otherwise update the new location_quotient values
  if((loc_quotient_activity_selector.val() === activity_val) && (loc_quotient_product_selector.val() === product_val)) {
    loc_quotient_activity_selector.val("");
    loc_quotient_product_selector.val("");
  }
  else {
    loc_quotient_activity_selector.val(activity_val);
    loc_quotient_product_selector.val(product_val);
  }
  area_index_form.submit();
});

$(document).on('grid_label_click', '#areas_index', function (event) {
  var area_index_form = $(".areas-index-form"),
      value = event.detail.label,
      type = event.detail.type,
      container_num = event.detail.container_num,
      loc_quotient_activity_selector = area_index_form.find("#containers_"+container_num+"_location_quotient_activity"),
      loc_quotient_product_selector = area_index_form.find("#containers_"+container_num+"_location_quotient_product");

  if (type == "activity"){
    set_loc_quotient_activity_product_in_area_index_form(loc_quotient_activity_selector, loc_quotient_product_selector, value);
  }
  else {
    set_loc_quotient_activity_product_in_area_index_form(loc_quotient_product_selector, loc_quotient_activity_selector, value);
  }
  area_index_form.submit();
});

// if label is clicked then set the right value for location_quotient_activity and location_quotient_product
function set_loc_quotient_activity_product_in_area_index_form(loc_quotient_selector_1, loc_quotient_selector_2, value){
  // if same label is clicked twice then set the location_quotient_activity and location_quotient_product to empty string
  if((loc_quotient_selector_1.val() == value)&&(loc_quotient_selector_2.val() == "ANY")){
    loc_quotient_selector_1.val("");
    loc_quotient_selector_2.val("");
  }
  else {
    loc_quotient_selector_1.val(value);
    loc_quotient_selector_2.val("ANY");
  }
}

// this is the click event handler for clicking the dataset: xxx radio button
$(document).on('click', '.region-view-collapse-div .styled-radio-button-card', function () {
  var value = JSON.parse($(this).find('input').val()),
      container_number = $(this).parents(".area-container").data("container-number"),
      area_index_form = $(".areas-index-form");
  area_index_form.find("#containers_" + container_number + "_data_source").val(value.data_source);
  area_index_form.find("#containers_"+container_number+"_hierarchy_id").val(value.hierarchy_id);
  area_index_form.submit();
});

// this is the click event handler for clicking the filter for marker radio button
$(document).on('click', '.map-marker-options-container .styled-radio-button-card', function() {
  var value = $(this).find('input').val(),
    container_number = $(this).parents(".area-container").data("container-number"),
    area_index_form = $(".areas-index-form");
  area_index_form.find("#containers_" + container_number + "_marker").val(value);
  area_index_form.submit();
});

$(document).on('click', '.grid-notification-container .grid-notification-clear-filter', function () {
  var container_num = $(this).parent().data("container-number"),
      area_index_form = $(".areas-index-form");
  if (area_index_form.length > 0) {
    // this part should be coming from index page
    area_index_form.find("#containers_" + container_num + "_location_quotient_activity").val("");
    area_index_form.find("#containers_" + container_num + "_location_quotient_product").val("");
    area_index_form.submit();
  } else {
    // this part should be coming from show page
    reset_activity_resource_on_show();
  }
});

// this method is used when the notification clear filter is selected on show page
function reset_activity_resource_on_show() {
  var base_url = window.location.href.split('?')[0],
      url_params = new URLSearchParams(window.location.search);

  url_params.delete('activity');
  url_params.delete('resource');
  url_params.delete('sector');
  url_params.delete('page');
  url_params.delete('functional_taxonomy');

  Turbolinks.visit(base_url + '?' + url_params.toString());
}

// allow treemap visual to resize based on window width
$(window).on("resize", function() {
  var treemap_container = $("#tree_map"),
      treemap_visual = window.tree_map;

  if (treemap_container.length && treemap_visual) {
    treemap_visual.resize();
  }
});
