
// trigger click to check the checkbox
$(document).on('click', '.fly-checkbox', function () {
  $(this).find('input').trigger('click');
});

// prevents the events from infinitely cycling between .fly-checkbox click and
// .fly-checkbox input click
$(document).on('click', '.fly-checkbox input', function (e) {
  e.stopPropagation();
});

// set the checked property when clicked
$(document).on('click', '.fly-radio', function (e) {
  $(this).find('input').prop("checked", true);
});

// when you click on the fly-file div click on the file linput to pull up the
// dialog box.
$(document).on('click', '.fly-file', function (e) {
  $(this).find('input').trigger('click');
});

// prevents the events from infinitely cycling between .fly-file click and
// .fly-file input click
$(document).on('click', '.fly-file input', function (e) {
  e.stopPropagation();
});

$(document).on('change', '.fly-file input', function (e) {
  // remotipart is messing with this event.  This works for now but is not the
  // best it will update all file inputs rather than just this one.  I would
  // prefer to do: $(this).siblings('.filename')
  var label = $('.fly-file .filename');

  if (this.files.length > 0) {
    label.attr('valid', true).text(this.files[0].name);
  } else {
    label.attr('valid', null).text('No file chosen');
  }
});
