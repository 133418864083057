// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
var timeout_id = '';

$(document).on('keyup', 'form.area-searches-form', function() {
  const form = $(this);
  var area_search_term = form.find('#area_search_search_term');
  var area_search_display = form.find('#area_search_display');

  if (timeout_id != '') {
    clearTimeout(timeout_id);
  }

  const searchSubmit = () => {
    area_search_term.val(area_search_display.val());
    form.trigger('submit');
  }
  timeout_id = setTimeout(searchSubmit, 250);
});

$(document).on('click', ':not(form.area-searches-form)', function () {
  $(this).parent().find('div.location-search-results').empty();
});

$(document).on('click', 'form.area-searches-form', function () {
  const form = $(this);
  var area_search_term = form.find('#area_search_search_term');

  if (timeout_id != '') {
    clearTimeout(timeout_id);
  }

  if (area_search_term.val() === ''){
    timeout_id = setTimeout(() => form.trigger('submit'), 250);
  }
});