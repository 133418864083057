// automatic search based on key press
var timeout_id = '';
$(document).on('keyup', 'form.industry-sector-search-form', function() {
  const form = $(this);
  if (timeout_id != '') {
    clearTimeout(timeout_id);
  }

  const searchSubmit = () => {
    form.trigger('submit');
  }
  timeout_id = setTimeout(searchSubmit, 250);
});

// enable submit form when clicking on options
$(document).on('click', '.industry-sector-option', function() {
  var industry_id = $(this).data('industry-sector-id');

  $('#fm_id').val(industry_id)
  $('#estimated_staffing_patterns_form').submit()
});

// close search results if clicked out
$(document).on('click', ':not(form.industry-sector-search-form)', function () {
  $(this).parent().find('div.industry-sector-options-container').empty();
});
